import { RouterProvider } from "react-router-dom";

import { setupI18n } from "i18n";
import routerConfig from "common/routerConfig";

setupI18n();

const App = () => <RouterProvider router={routerConfig} />;

export default App;
