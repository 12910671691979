import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  capitalize,
  InfoIcon,
  ModalManager,
  Stack,
  TColorToken,
  Text,
  Toaster,
  BudgeUIProvider,
  ErrorBoundary,
  errorManager,
  isInvalidTokenError,
  ThunkErrorPayload,
} from "@budgeinc/budge-ui-core";
import { SessionContextProvider } from "SessionContext";
import ErrorManager from "components/ErrorManager";
import { budgeEnv, isDemo, isDev, isProd } from "utils/env";
import { errorLogger } from "api/BudgeApi";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PropsWithChildren, useMemo } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { TBaseMutationVariables } from "api/hooks/types";
import { useAppMessages } from "store/global";
import getStoreConfig from "store";
import { Provider as ReduxProvider } from "react-redux";

const { store } = getStoreConfig();

const queryCache = new QueryCache();

const QueryClientSetup = ({ children }: PropsWithChildren) => {
  const messages = useAppMessages();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            gcTime: 5 * 60 * 1000, // 15 mins
            staleTime: 30 * 60 * 1000, // 30 mins
          },
          mutations: {
            retry: 0,
            onError: (e, variables) => {
              const vars = variables as TBaseMutationVariables;

              // When migration is completed, set this to === false
              // so that by default the error modal will show up
              if (!vars?.showErrorModal) {
                // dont show error modal if explicitly set to false
                return;
              }

              let error: AxiosError<ErrorResponse> = e as any;
              const errorResponse = error.response?.data;
              const thunkError = e as ThunkErrorPayload;

              if (thunkError.apiError != null) {
                error = thunkError.apiError;
              }

              if (errorResponse && isInvalidTokenError(errorResponse)) {
                // dont show error modal in that case
                return;
              }

              errorManager.showError({
                key: errorResponse?.message,
                error: error?.response?.data,
              });
            },
          },
        },
      }),
    [messages]
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const RouterRoot = () => {
  const location = useLocation();

  return (
    <ReduxProvider store={store}>
      <SafeAreaProvider style={{ flex: 1, height: "100%" }}>
        <QueryClientSetup>
          <BudgeUIProvider>
            <ErrorBoundary
              onReset={() => window.location.reload()}
              onError={error => {
                if (error) {
                  errorLogger.logClientError(error);
                }
              }}
            >
              <Box f={1}>
                {!isProd && (
                  <Alert color={getTopBgColor()} contentColor="white" variant="filled" r={0} py="xs">
                    <Stack.Horizontal alignItems="center" justifyContent="center">
                      <Text color="white" fw="600" ta="center" variant="bodySmall">
                        You&apos;re in {capitalize(budgeEnv.toLowerCase())} mode
                      </Text>
                      {location.pathname !== "/diag" && (
                        <Link
                          to={{
                            pathname: "/diag",
                            search: `next=${location.pathname}`,
                          }}
                          style={{
                            display: "flex",
                          }}
                        >
                          <InfoIcon size={16} color="white" />
                        </Link>
                      )}
                    </Stack.Horizontal>
                  </Alert>
                )}
                <SessionContextProvider>
                  <Outlet />
                  <ErrorManager />
                  <ModalManager />
                  <Toaster />
                </SessionContextProvider>
              </Box>
            </ErrorBoundary>
          </BudgeUIProvider>
        </QueryClientSetup>
      </SafeAreaProvider>
    </ReduxProvider>
  );
};

const getTopBgColor = (): TColorToken | undefined => {
  if (isDev) return "green";
  if (isDemo) return "yellow.5";

  return "red"; // Test
};

export default RouterRoot;
